// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="Using TypeScript" location={location}>
    <SEO title="Using TypeScript" />
    <h1>Bu EnglishClub_uz YouTube Kanalining web sahifasi.</h1>
    <p>
      Bu web sahifada siz <em>ingliz tili bepul onlayn darsliklarini</em> topishingiz mumkin. <em>inglizcha hikoyalar va qo'shiqlarning tarjimasi, inglizcha so'zlar va gramatika darslarini</em> ushbu web sahifada kuzatib borishingiz mumkin.
    </p>
    
  
    <p>
      Bepul inglizcha video darsliklarni kuzatib borish uchun{" "}
      <a href="https://www.youtube.com/channel/UCbJjUTqbGjtaItKO6loJdwA">
        EnglishClub_uz kanaliga qo'shiling
      </a>
      .
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
